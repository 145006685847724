import Layout from "../../components/Layout";
import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Resources for Students | Schoolhouse by the Sea";
  const description =
    "We have a wide variety of student materials and curriculum across a number of categories.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Welcome Students!</h1>
            <p>
              Here you will find all the materials we've developed and curated
              to support your education. Check back regularly for new resources
              and experiences! We will be posting updates every Monday,
              Wednesday, and Friday to ensure you have access to fresh ideas,
              new ways to explore, and more strategies to strengthen your
              skills. Have a wonderful school year!
            </p>
          </div>
          <div className="content">
            <h2>
              <Link to="/students/arts-and-crafts">Arts and Crafts</Link>
            </h2>
            <h2>
              <Link to="/students/fingerplays">Fingerplays</Link>
            </h2>
            <h2>
              <Link to="/students/games">Games</Link>
            </h2>
            <h2>
              <Link to="/students/math-games">Math Games</Link>
            </h2>
            <h2>
              <Link to="/students/monthly-challenges">Monthly Challenges</Link>
            </h2>
            <h2>
              <Link to="/students/movement">Movement</Link>
            </h2>
            <h2>
              <Link to="/students/music">Music</Link>
            </h2>
            <h2>
              <Link to="/students/poems-and-verses">Poems and Verses</Link>
            </h2>
            <h2>
              <Link to="/students/read-alouds">Read Alouds</Link>
            </h2>
            <h2>
              <Link to="/students/recipes">Recipes</Link>
            </h2>
            <h2>
              <Link to="/students/science">Science</Link>
            </h2>
            <h2>
              <Link to="/students/spelling-games">Spelling Games</Link>
            </h2>
            <h2>
              <Link to="/students/stories">Stories</Link>
            </h2>
          </div>
          <div className="content">
            <div className="is-size-4">
              Resource Categories Coming Soon Include:
            </div>
            <ul>
              {/*<h2>*/}
              {/*  <Link to="/students/social-emotional-learning">*/}
              <li className="is-size-5">Social Emotional Learning</li>
              {/*  </Link>*/}
              {/*</h2>*/}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};
